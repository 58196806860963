import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";

export default {
    name: 'MakePremium',
    props: {
        activate: Function,
        vipEndDate: String,
    },
    components: {
        Dropdown
    },
    computed: {
        vipDateFormatted() {
            const date = new Date(this.$props.vipEndDate);
            return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate();
        }
    },
    data() {
        return {
            selectedPlan: null,
            premiumPlans: {}
        }
    },
    watch: {
        selectedPlan(newVal, oldVal) {
            this.$emit('update:modelValue', newVal);
        }
    },
    mounted() {
        this.premiumPlans = {}
        this.premiumPlans[this.$t('plan-regular-vendor')] = 'regular';
        this.premiumPlans[this.$t('plan-premium-7-vendor')] = 'premium-7';
        this.premiumPlans[this.$t('plan-premium-30-vendor')] = 'premium-30';
        // this.premiumPlans[this.$t('plan-premium-30-plus-vendor')] = 'premium-30-plus';
    },
    methods: {
        selectPlan(plan) {
            let hasEnoughCredits = false;
            const userCredits = this.$store.state.user.info.credits;
            switch (plan) {
                case 'regular':
                    hasEnoughCredits = true;
                    break;
                case 'premium-7':
                    hasEnoughCredits = userCredits >= 4;
                    break;
                case 'premium-30':
                    hasEnoughCredits = userCredits >= 9;
                    break;
                case 'premium-30-plus':
                    hasEnoughCredits = userCredits >= 35;
                    break;
            }
            if (hasEnoughCredits) {
                this.selectedPlan = plan;
            } else {
                this.$store.dispatch('utils/openNotificationModal', {
                    message: this.$t('you-have-no-enough-credits-for-selected-plan'),
                    type: 'error'
                });
            }
        },
    }
}